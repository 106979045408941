import { paths } from 'types/api';
import { CustomField } from 'types/common';
import {
  AnalyticsTags,
  baseApi,
  KeysToCamelcase,
  RealogramsTags,
} from '../baseApi';

export type GetRealogramCandidatesParams = KeysToCamelcase<
  paths['/api/v1/realogram_candidates']['get']['parameters']['query']
>;
type GetRealogramCandidatesResponse =
  paths['/api/v1/realogram_candidates']['get']['responses']['200']['content']['application/json'];

type GetRealogramCandidateParams = KeysToCamelcase<
  paths['/api/v1/realogram_candidates/{realogram_candidate_id}']['get']['parameters']['path']
>;
type GetRealogramCandidateResponse =
  paths['/api/v1/realogram_candidates/{realogram_candidate_id}']['get']['responses']['200']['content']['application/json'] & {
    realogram_candidate?: { custom_field?: CustomField };
  };

type PostRealogramCandidateImageParams = KeysToCamelcase<
  paths['/api/v1/realogram_candidates/image']['post']['requestBody']['content']['application/json']
>;
type PostRealogramCandidateImageResponse =
  paths['/api/v1/realogram_candidates/image']['post']['responses']['200']['content']['application/json'];

type DeleteRealogramCandidateParams = KeysToCamelcase<
  paths['/api/v1/realogram_candidates/{realogram_candidate_id}']['delete']['parameters']['path']
>;
type DeleteRealogramCandidateResponse = KeysToCamelcase<
  paths['/api/v1/realogram_candidates/{realogram_candidate_id}']['delete']['responses']['200']['content']['application/json']
>;

type UpdateFaceParams = KeysToCamelcase<
  paths['/api/v1/realogram_candidates/{realogram_candidate_id}/faces/{face_id}']['put']['parameters']['path'] &
    paths['/api/v1/realogram_candidates/{realogram_candidate_id}/faces/{face_id}']['put']['requestBody']['content']['application/json']
>;
type UpdateFaceResponse =
  paths['/api/v1/realogram_candidates/{realogram_candidate_id}/faces/{face_id}']['put']['responses']['200']['content']['application/json'];

type DeleteFaceParams = KeysToCamelcase<
  paths['/api/v1/realogram_candidates/{realogram_candidate_id}/faces/{face_id}']['delete']['parameters']['path']
>;
type DeleteFaceResponse =
  paths['/api/v1/realogram_candidates/{realogram_candidate_id}/faces/{face_id}']['delete']['responses']['200']['content']['application/json'];

type DeleteShelfBoardParams = KeysToCamelcase<
  paths['/api/v1/realogram_candidates/{realogram_candidate_id}/shelf_boards/{shelf_board_id}']['delete']['parameters']['path']
>;
type DeleteShelfBoardResponse =
  paths['/api/v1/realogram_candidates/{realogram_candidate_id}/shelf_boards/{shelf_board_id}']['delete']['responses']['200']['content']['application/json'];

type SplitCompartmentParams = KeysToCamelcase<
  paths['/api/v1/realogram_candidates/{realogram_candidate_id}/faces/{face_id}/split']['put']['parameters']['path']
> &
  KeysToCamelcase<
    paths['/api/v1/realogram_candidates/{realogram_candidate_id}/faces/{face_id}/split']['put']['requestBody']['content']['application/json']
  >;
type SplitCompartmentResponse =
  paths['/api/v1/realogram_candidates/{realogram_candidate_id}/faces/{face_id}/split']['put']['responses']['200']['content']['application/json'];

type AddProductFaceParams = KeysToCamelcase<
  paths['/api/v1/realogram_candidates/{realogram_candidate_id}/faces']['post']['parameters']['path'] &
    paths['/api/v1/realogram_candidates/{realogram_candidate_id}/faces']['post']['requestBody']['content']['application/json']
>;
type AddProductFaceResponse =
  paths['/api/v1/realogram_candidates/{realogram_candidate_id}/faces']['post']['responses']['200']['content']['application/json'];

// see) https://staging.shelf.pfn.dev/docs#/realogram_candidates
export const realogramCandidatesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    listRealogramCandidates: builder.query<
      GetRealogramCandidatesResponse,
      GetRealogramCandidatesParams
    >({
      query: (params) => ({
        url: 'realogram_candidates',
        params: {
          offset: params?.offset,
          limit: params?.limit,
          owner_id: params?.ownerId,
          first_order: params?.firstOrder,
          store_id: params?.storeId,
          store_bay_id: params?.storeBayId,
          created_at_start: params?.createdAtStart,
          created_at_end: params?.createdAtEnd,
        },
      }),
      providesTags: [RealogramsTags.ListRealogram],
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        // check is filters changed or not
        if (currentCache.pager.offset === newItems.pager.offset) {
          currentCache.pager = newItems.pager;
          currentCache.realogram_candidates = [
            ...newItems.realogram_candidates,
          ];
        } else {
          currentCache.realogram_candidates.push(
            ...newItems.realogram_candidates
          );
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getRealogramCandidate: builder.query<
      GetRealogramCandidateResponse,
      GetRealogramCandidateParams
    >({
      query: (params) => ({
        url: `realogram_candidates/${params.realogramCandidateId}`,
      }),
      providesTags: [RealogramsTags.UpdateProductLists],
    }),

    createRealogramCandidateImage: builder.mutation<
      PostRealogramCandidateImageResponse,
      PostRealogramCandidateImageParams
    >({
      query: (params) => ({
        url: 'realogram_candidates/image',
        method: 'POST',
        body: {
          store_bay_id: params.storeBayId,
          shot_type: params.shotType,
          shot_count: params.shotCount,
          custom_field: params.customField,
        },
      }),
    }),
    deleteRealogram: builder.mutation<
      DeleteRealogramCandidateResponse,
      DeleteRealogramCandidateParams
    >({
      query: (params) => ({
        url: `realogram_candidates/${params.realogramCandidateId}`,
        method: 'DELETE',
      }),
    }),

    updateFace: builder.mutation<UpdateFaceResponse, UpdateFaceParams>({
      query: (params) => ({
        url: `realogram_candidates/${params.realogramCandidateId}/faces/${params.faceId}`,
        method: 'PUT',
        body: {
          primary_candidate_product_id: params.primaryCandidateProductId,
          out_of_stock: params.outOfStock,
          revise_source: params.reviseSource,
        },
      }),
      invalidatesTags: [AnalyticsTags.GetRealogramAnalytics],
    }),
    deleteFace: builder.mutation<DeleteFaceResponse, DeleteFaceParams>({
      query: (params) => ({
        url: `realogram_candidates/${params.realogramCandidateId}/faces/${params.faceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [AnalyticsTags.GetRealogramAnalytics],
    }),
    deleteShelfBoard: builder.mutation<
      DeleteShelfBoardResponse,
      DeleteShelfBoardParams
    >({
      query: (params) => ({
        url: `realogram_candidates/${params.realogramCandidateId}/shelf_boards/${params.shelfBoardId}`,
        method: 'DELETE',
      }),
      //TODO:use invalidate/provide tags
    }),
    splitCompartment: builder.mutation<
      SplitCompartmentResponse,
      SplitCompartmentParams
    >({
      query: ({ realogramCandidateId, faceId, ...params }) => ({
        url: `/realogram_candidates/${realogramCandidateId}/faces/${faceId}/split`,
        method: 'PUT',
        body: {
          split_axis: params.splitAxis,
          split_num: params.splitNum,
        },
      }),
      invalidatesTags: [RealogramsTags.UpdateProductLists],
    }),
    addProductFace: builder.mutation<
      AddProductFaceResponse,
      AddProductFaceParams
    >({
      query: (params) => ({
        url: `realogram_candidates/${params.realogramCandidateId}/faces`,
        method: 'POST',
        body: {
          shelf_board_id: params.shelfBoardId,
          bbox: params.bbox,
          in_stock: params.inStock,
        },
      }),
    }),
  }),
});

export const {
  useListRealogramCandidatesQuery,
  useGetRealogramCandidateQuery,
  useCreateRealogramCandidateImageMutation,
  useDeleteRealogramMutation,
  useUpdateFaceMutation,
  useDeleteFaceMutation,
  useDeleteShelfBoardMutation,
  useSplitCompartmentMutation,
  useAddProductFaceMutation,
} = realogramCandidatesApi;
