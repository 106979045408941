import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  ListSubheader as MuiListSubheader,
  Switch,
  SxProps,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';

type Props = {
  title: string;
  backgroundColor?: string;
  color?: string;
  sx?: SxProps;
  isSKU: boolean;
  isRealogram?: boolean;
  isCanEditFace?: boolean;
  isBucket?: boolean;
  handleClick?: () => void;
  handleChangeSKU: VoidFunction;
};

export const ListSubheader: FC<Props> = ({
  title,
  backgroundColor,
  color,
  sx,
  isSKU,
  isRealogram,
  isCanEditFace,
  isBucket = false,
  handleChangeSKU,
  handleClick,
}) => (
  <MuiListSubheader
    sx={{
      backgroundColor: backgroundColor,
      height: 28,
      display: 'flex',
      alignItems: 'center',
      color: color,
      ...sx,
    }}
  >
    {title}
    {isRealogram && isBucket && (
      <Box component="div" display="flex" alignItems="center">
        <Typography color={theme.palette.textBlack.secondary} variant="body3">
          SKU
        </Typography>
        <Switch checked={isSKU} onChange={handleChangeSKU} />
      </Box>
    )}
    {isRealogram && isCanEditFace && !isBucket && (
      <Button
        onClick={handleClick}
        sx={{
          width: '78px',
          height: '24px',
          p: 0,
          px: 1,
          lineHeight: '11px',
          color: theme.palette.textBlack.primary,
          ':hover': {
            bgcolor: theme.palette.backgroundBlack.hover,
          },
        }}
      >
        <Add sx={{ height: '16px', width: '16px' }} />
        <Typography variant="caption1">区画追加</Typography>
      </Button>
    )}
  </MuiListSubheader>
);
