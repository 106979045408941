import { CircularSpinner, ProductEmptyResult } from '@components/molecules';
import { ChangeBboxButton } from '@components/molecules/changeBboxButton/changeBboxButton';
import { FaceAreaControllerBox } from '@components/molecules/faceAreaControllerBox/faceAreaControllerBox';
import { HeatMap } from '@components/organisms/heatMap/heatMap';
import { useRealogramCandidateImage } from '@hooks/useImage';
import { useRealogramImageRatio } from '@hooks/useRealogramImageRatio';
import { useZoomController } from '@hooks/useZoomController';
import { Box, Typography } from '@mui/material';
import { updateRatio } from '@reducers/realogramCandidate';
import { useAppDispatch } from '@store/index';
import {
  formatNumberToYen,
  profitTabGrossProfit,
  profitTabSales,
} from '@utils/const';
import { FC, useEffect, useRef, useState } from 'react';
import {
  ProfitTab,
  Rate,
  ShelfDetailMode,
  ShelfDetailView,
} from 'types/common';
import { ProductReport } from 'types/products';
import {
  BboxColors,
  RealogramSelectedItem,
  RealogramShelfBoard,
  UnknownProduct,
} from 'types/realogram';
import { theme } from '../../../theme';
import {
  firstShot,
  getMaxValueInReport,
  refetchImageTime,
  secondShot,
  timer,
} from './utils';
import { ImageAndBbox } from './fragments/ImageAndBbox';
import { RealogramZoomController } from '../zoomController/realogramZoomController';
import { Error } from '@mui/icons-material';
import { StoreBayChangeButtonState } from '@components/pages/scannerDetails/hooks/useChangeStoreBay';
import { StoreBayButton } from './fragments/storeBayButton';

type Props = {
  imageIsLoading: boolean;
  markerEnabled?: boolean;
  defaultBboxColor?: BboxColors;
  realogramCandidateId: number;
  shelfBoards?: RealogramShelfBoard[];
  view: ShelfDetailView;
  mode: ShelfDetailMode;
  handleClickBbox: (selectedItem: RealogramSelectedItem) => void;
  handleChangeLoading: () => void;
  createdAt: string;
  rate?: Rate;
  onClickNext?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickPrev?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  profitTab?: ProfitTab;
  productReports?: ProductReport[];
  comparedProductReports?: ProductReport[];
  comparisonSameIdsSet?: Set<number>;
  isComparingBox?: boolean;
  isDisabledAction?: boolean;
  isUnknownProductsReviseMode?: boolean;
  unknownProductsInfo?: UnknownProduct[];
  isNextDisabled?: boolean;
  isPrevDisabled?: boolean;
  isRealogramLoading?: boolean;
  hasData?: boolean;
  getPreviousStoreBay?: VoidFunction;
  getNextStoreBay?: VoidFunction;
  isChangeButtonActive?: StoreBayChangeButtonState;
};

export const FlatRealogramImage: FC<Props> = ({
  imageIsLoading,
  markerEnabled = false,
  defaultBboxColor,
  realogramCandidateId,
  shelfBoards,
  view,
  mode,
  handleClickBbox,
  handleChangeLoading,
  createdAt,
  rate,
  onClickNext,
  onClickPrev,
  profitTab,
  productReports,
  comparisonSameIdsSet,
  isComparingBox = false,
  isDisabledAction = false,
  isUnknownProductsReviseMode = false,
  unknownProductsInfo,
  comparedProductReports,
  isNextDisabled = false,
  isPrevDisabled = false,
  isRealogramLoading = false,
  hasData = true,
  getPreviousStoreBay,
  getNextStoreBay,
  isChangeButtonActive,
}) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLElement>();
  const dataImage = isComparingBox ? 'comparison-image' : 'original-image';

  const [imageElement, setImageElement] = useState<HTMLImageElement | null>(
    null
  );
  const { ratio, calcRatio } = useRealogramImageRatio(
    imageIsLoading,
    imageElement,
    ref,
    mode,
    true,
    true
  );
  const {
    image: firstImage,
    error: firstImageError,
    isLoading: isFirstImageLoading,
    getImage: refetchFirstImage,
    setImage: setFistImage,
    setError: setFirstImageError,
  } = useRealogramCandidateImage(
    {
      realogramCandidateId,
      size: 'huge',
      shotIndex: 1,
    },
    { skip: false, isNoCache: true }
  );
  const {
    image: secondImage,
    error: secondImageError,
    isLoading: isSecondImageLoading,
    getImage: refetchSecondImage,
    setImage: setSecondImage,
    setError: setSecondImageError,
  } = useRealogramCandidateImage(
    {
      realogramCandidateId,
      size: 'huge',
      shotIndex: 2,
    },
    { skip: false, isNoCache: true }
  );
  const originalMaxThreshold = getMaxValueInReport(profitTab, productReports);
  const comparedMaxThreshold = getMaxValueInReport(
    profitTab,
    comparedProductReports
  );
  const imageError = firstImageError || secondImageError;
  const imageLoading =
    isFirstImageLoading || isSecondImageLoading || ratio === 0;

  const maxThreshold =
    originalMaxThreshold > comparedMaxThreshold
      ? originalMaxThreshold
      : comparedMaxThreshold;
  const isPrice = profitTab === profitTabSales;
  const [bboxEnabled, setBboxEnabled] = useState(true);
  const firstShotBboxes = shelfBoards?.filter(
    (el) => el.shot_index === firstShot
  );
  const secondShotBboxes = shelfBoards?.filter(
    (el) => el.shot_index === secondShot
  );

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const isDefault = mode === 'default';
  /** 「荒利」の場合ヒートマップを表示しない */
  const isHeatMap =
    view === 'profit' &&
    isDefault &&
    profitTab !== undefined &&
    profitTab !== profitTabGrossProfit &&
    hasData;

  /** 画像の再取得時のエラー */
  const isImageRefetchError =
    firstImageError || !refetchFirstImage || !refetchSecondImage;

  const {
    zoomScale,
    isDisableReset,
    isDisableZoomIn,
    isDisableZoomOut,
    handleDecrementZoom,
    handleIncrementZoom,
    handleResetZoom,
  } = useZoomController();

  // eslint-disable-next-line react-hooks/exhaustive-deps -- adding deps array will not update imageElement to the newest element
  useEffect(() => {
    const imageElement: HTMLImageElement | null = document.querySelector(
      `[data-image="${dataImage}-1"]`
    );
    if (imageElement) {
      setImageElement(imageElement);
    }
  });

  useEffect(() => {
    if (ratio) {
      dispatch(updateRatio(ratio));
    }
  }, [ratio, dispatch]);

  useEffect(() => {
    if (!firstImage && firstImageError) {
      const intervalRefetchFirstImage = setInterval(async () => {
        const result = refetchFirstImage && (await refetchFirstImage());
        if (result) {
          setFistImage && setFistImage(URL.createObjectURL(result));
          setFirstImageError && setFirstImageError(undefined);
        }
      }, timer);

      return () => {
        clearInterval(intervalRefetchFirstImage);
      };
    }
  }, [
    firstImage,
    firstImageError,
    isFirstImageLoading,
    refetchFirstImage,
    setFirstImageError,
    setFistImage,
  ]);

  useEffect(() => {
    if (!secondImage && secondImageError) {
      const intervalRefetchSecondImage = setInterval(async () => {
        const result = refetchSecondImage && (await refetchSecondImage());
        if (result) {
          setSecondImage && setSecondImage(URL.createObjectURL(result));
          setSecondImageError && setSecondImageError(undefined);
        }
      }, timer);

      return () => {
        clearInterval(intervalRefetchSecondImage);
      };
    }
  }, [
    secondImage,
    secondImageError,
    refetchSecondImage,
    setSecondImage,
    setSecondImageError,
    isSecondImageLoading,
  ]);

  useEffect(() => {
    if (isImageRefetchError || !imageElement) return;

    if (ratio === 0) calcRatio();
    // 画像が表示できていないときに再取得する
    if (imageElement.naturalWidth === 0 && imageElement.naturalHeight === 0) {
      setTimeout(() => {
        refetchFirstImage().catch((error) => console.log(error));
        refetchSecondImage().catch((error) => console.log(error));
      }, refetchImageTime);
    } else {
      setWidth(imageElement.naturalWidth * ratio);
      setHeight(imageElement.naturalHeight * ratio);
    }
  }, [
    isImageRefetchError,
    imageElement,
    refetchFirstImage,
    refetchSecondImage,
    isRealogramLoading,
    createdAt,
    ratio,
    calcRatio,
  ]);

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      position="relative"
      flex={1}
      sx={{ overflow: 'hidden' }}
    >
      <Box
        ref={ref}
        component="div"
        display="flex"
        alignItems="center"
        alignSelf="center"
        position="relative"
        width={imageError ? '100%' : 'unset'}
        flex={1}
        sx={{
          overflow: 'auto',
          scrollbarWidth: 'none', // Firefox用
          '-ms-overflow-style': 'none', // IE用
          '&::-webkit-scrollbar': {
            display: 'none', // WebKit系ブラウザ（Chrome, Safariなど）用
          },
        }}
      >
        {imageError ? (
          <Box component="div" width="100%" height="100%">
            <ProductEmptyResult
              title="画像が表示できません"
              message="時間を置いて再度ご確認ください。"
            />
          </Box>
        ) : hasData && firstImage && secondImage ? (
          <Box
            component="div"
            sx={{
              transform: `scale(${zoomScale},${zoomScale})`,
              position: 'relative',
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              gap: '3px',
              justifyContent: 'center',
              height: '100%',
              transformOrigin: 'left top',
            }}
          >
            <ImageAndBbox
              markerEnabled={markerEnabled}
              defaultBboxColor={defaultBboxColor}
              view={view}
              mode={mode}
              handleClickBbox={
                view === 'default' ? handleClickBbox : () => void 0
              }
              handleChangeLoading={handleChangeLoading}
              rate={rate}
              profitTab={profitTab}
              productReports={productReports}
              comparisonSameIdsSet={comparisonSameIdsSet}
              isUnknownProductsReviseMode={isUnknownProductsReviseMode}
              unknownProductsInfo={unknownProductsInfo}
              height={height}
              width={width}
              realogramImage={firstImage}
              dataImage={dataImage + '-1'}
              setWidth={setWidth}
              setHeight={setHeight}
              imageElement={imageElement}
              ratio={ratio}
              imageShotBboxes={!isRealogramLoading ? firstShotBboxes : []}
              bboxEnabled={bboxEnabled}
              maxThreshold={maxThreshold}
              isBucket
            />
            <ImageAndBbox
              markerEnabled={markerEnabled}
              defaultBboxColor={defaultBboxColor}
              view={view}
              mode={mode}
              handleClickBbox={
                view === 'default' ? handleClickBbox : () => void 0
              }
              handleChangeLoading={handleChangeLoading}
              rate={rate}
              profitTab={profitTab}
              productReports={productReports}
              comparisonSameIdsSet={comparisonSameIdsSet}
              isUnknownProductsReviseMode={isUnknownProductsReviseMode}
              unknownProductsInfo={unknownProductsInfo}
              height={height}
              width={width}
              realogramImage={secondImage}
              dataImage={dataImage + '-2'}
              setWidth={setWidth}
              setHeight={setHeight}
              imageElement={imageElement}
              ratio={ratio}
              imageShotBboxes={!isRealogramLoading ? secondShotBboxes : []}
              bboxEnabled={bboxEnabled}
              maxThreshold={maxThreshold}
              isBucket
            />
          </Box>
        ) : hasData && imageLoading ? (
          <Box
            component="div"
            sx={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <CircularSpinner />
          </Box>
        ) : (
          <Box
            component="div"
            color={theme.palette.textBlack.disabled}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="8px"
          >
            <Error />
            <Typography variant="subtitle1">
              {createdAt.split(' ')[0]}のスキャン結果はありません
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        component="div"
        py={1.25}
        px={1.25}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={56}
        sx={{
          backgroundColor: theme.palette.white.primary,
          position: 'relative',
        }}
      >
        <FaceAreaControllerBox
          createdAt={hasData ? createdAt : createdAt.split(' ')[0]}
          onClickNext={onClickNext}
          onClickPrev={onClickPrev}
          sx={{ fontSize: '14px', color: theme.palette.textBlack.primary }}
          isNextDisabled={isNextDisabled || !hasData}
          isPrevDisabled={isPrevDisabled || !hasData}
        />
        {!imageError && (
          <>
            <ChangeBboxButton
              bboxEnabled={bboxEnabled}
              setBboxEnabled={setBboxEnabled}
              top={0}
              left={0}
              styleBox={{ position: 'relative', transform: 'none' }}
              styleBtn={{ width: '48px', height: '40px' }}
              isDisabledAction={isDisabledAction || !hasData}
            />
            <RealogramZoomController
              isDisabled={isDisabledAction || !hasData}
              isDisableReset={isDisableReset}
              isDisableZoomIn={isDisableZoomIn}
              isDisableZoomOut={isDisableZoomOut}
              handleIncrementZoom={handleIncrementZoom}
              handleDecrementZoom={handleDecrementZoom}
              handleTriggerZoom={handleResetZoom}
            />
          </>
        )}
      </Box>
      {isHeatMap && (
        <Box
          component="div"
          sx={{
            position: 'absolute',
            // move to up because of next/prev store bay button created(only default mode)
            bottom: '134px',
            left: '16px',
          }}
        >
          <HeatMap
            start={isPrice ? '¥0' : '0'}
            end={
              isPrice
                ? formatNumberToYen(maxThreshold)
                : maxThreshold.toString()
            }
          />
        </Box>
      )}
      {/* next/prev store bay button */}
      {isDefault && (
        <StoreBayButton
          isChangeButtonActive={isChangeButtonActive}
          getNextStoreBay={getNextStoreBay}
          getPreviousStoreBay={getPreviousStoreBay}
        />
      )}
    </Box>
  );
};
