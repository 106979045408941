import { ListSubheader } from '@components/molecules/listSubheader/listSubheader';
import { Box, List, Typography } from '@mui/material';
import { selectPlanogramEditorState } from '@reducers/planogramEditor/selectors';
import { useAppSelector } from '@store/index';
import { embed } from '@utils/const';
import { findProductInSales, isSelectedCompartment } from '@utils/planogram';
import { hasProductTag } from '@utils/product';
import { FC, Fragment, MutableRefObject, useEffect, useState } from 'react';
import { theme } from 'theme';
import { Product, ProfitTab } from 'types/common';
import { PlanogramPlan, ProductPosition } from 'types/planogram';
import { ProductReportProduct } from 'types/products';
import { EmptyCompartment } from '../realogramCandidatesList/fragments/emptyCompartment';
import { ShelfListHeader } from '../shelfListHeader/shelfListHeader';
import { ShelfListItem } from '../shelfListItem/shelfListItem';
import { HeaderOptional } from './fragments/headerOptional';
import { ListOptional } from './fragments/listOptional';

type Props = {
  refs: MutableRefObject<Map<string, null | HTMLDivElement>>;
  products?: Product[];
  productsLayout: PlanogramPlan['products_layout'];
  handleClickListItem: (position: ProductPosition, product?: Product) => void;
  profitTab: ProfitTab;
  productSales?: ProductReportProduct[];
};

export const CompartmentsTable: FC<Props> = ({
  refs,
  products,
  productsLayout,
  handleClickListItem,
  profitTab,
  productSales,
}) => {
  const {
    productPosition,
    detailView: view,
    productTag,
  } = useAppSelector(selectPlanogramEditorState);
  const [showOrganizationProductId, setShowOrganizationProductId] =
    useState(false);

  useEffect(() => {
    const embedParam = new URLSearchParams(window.location.search).get('embed');
    if (embedParam === embed) setShowOrganizationProductId(true);
  }, []);

  return (
    <>
      <ShelfListHeader>
        <HeaderOptional profitTab={profitTab} />
      </ShelfListHeader>
      <List
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
        subheader={<Box component="li" />}
      >
        {!!productsLayout.length && //[]の時(ロード中)にemptyを表示しない
          //TODO: リファクタ
          (productsLayout.every(({ row }) => !row.length) ||
            ((view === 'productFlag' || view === 'rate') &&
              productsLayout.every(({ row }) => {
                return row.every(({ product_id }) => {
                  const product = products?.find((p) => p.id === product_id);
                  return !hasProductTag(productTag, product?.detail?.tags);
                });
              }))) &&
          view !== 'default' &&
          view !== 'rate' && (
            <Typography sx={{ color: '#999999', mt: 5, textAlign: 'center' }}>
              該当する区画がありません
            </Typography>
          )}
        {productsLayout.map(({ row }, shelfIndex) => {
          return (
            <Fragment key={shelfIndex}>
              {view === 'default' && (
                <ListSubheader
                  title={`${shelfIndex + 1}段目`}
                  backgroundColor={theme.palette.backgroundBlack.active}
                  color={theme.palette.textBlack.primary}
                  sx={{ fontWeight: 400, height: 30 }}
                  isSKU={false}
                  handleChangeSKU={() => void 0}
                />
              )}
              {view === 'default' && !row.length ? (
                <EmptyCompartment
                  emptyText="棚に配置されている商品はありません"
                  // eslint-disable-next-line @typescript-eslint/no-empty-function -- TODOリファクタリング
                  handleClickDeleteShelfBoard={() => {}}
                  isSKU={false}
                />
              ) : (
                row.map((compartment, compartmentIndex) => {
                  const product = products?.find(
                    (p) => p.id === compartment.product_id
                  );
                  const position = {
                    indexX: 0,
                    indexY: productsLayout.length - shelfIndex - 1, //配列をreverseしているためindexYも反転させる
                    subPosition: {
                      indexX: compartmentIndex,
                      indexY: 0,
                    },
                  };
                  if (
                    view === 'productFlag' &&
                    !hasProductTag(productTag, product?.detail?.tags)
                  ) {
                    return <Fragment key={compartmentIndex} />;
                  }
                  if (
                    view === 'profit' &&
                    !findProductInSales(compartment.product_id, productSales)
                  ) {
                    return <Fragment key={compartmentIndex} />;
                  }
                  return (
                    <ShelfListItem
                      key={compartmentIndex}
                      refs={refs}
                      view={view}
                      refKey={JSON.stringify(position)}
                      product={product}
                      isSelected={isSelectedCompartment(
                        position,
                        productPosition
                      )}
                      handleClick={() => handleClickListItem(position, product)}
                      name={product?.name}
                      organizationProductId={
                        showOrganizationProductId
                          ? product?.detail?.organization_product_id
                          : ''
                      }
                      eanCode={product?.detail?.ean_code}
                      productCode={product?.detail?.organization_product_id}
                    >
                      <ListOptional
                        compartment={compartment}
                        handleClick={() =>
                          handleClickListItem(position, product)
                        }
                        profitTab={profitTab}
                      />
                    </ShelfListItem>
                  );
                })
              )}
            </Fragment>
          );
        })}
      </List>
    </>
  );
};
