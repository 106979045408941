import { useBreakpoint } from '@hooks/useBreakpoint';
import { Box } from '@mui/material';
import { useGetProductQuery } from '@reducers/shelfAppsApi';
import {
  findMostHighScore,
  getProductDisplayValues,
  isInferredAsProduct,
} from '@utils/product';
import { FC, useMemo } from 'react';
import { Product, ProfitTab, ShelfDetailView } from 'types/common';
import { ProductSalesReport } from 'types/products';
import {
  ProductCandidate as ProductCandidateType,
  RealogramSelectedItem,
} from 'types/realogram';
import { HorizontalCandidate } from './fragments/horizontalCandidate';
import { VerticalCandidate } from './fragments/verticalCandidate';
import { SlideButtons } from '@components/molecules/slideButtons/SlideButtons';

type Props = {
  products?: Product[];
  selectedItem: RealogramSelectedItem;
  referenceImage: string;
  selectNext: () => void;
  selectPrevious: () => void;
  handleModalOpen: () => void;
  shouldButtonBeDisabled: (value: 'next' | 'prev') => boolean;
  handleOpenReferenceImagePreview: () => void;
  realogramDetailView: ShelfDetailView;
  productSalesReport?: ProductSalesReport[];
  profitTab?: ProfitTab;
  setSelectedProductZoomIn: (product?: Product) => void;
  realogramCandidateId: number;
  isCanNotEditProductAndCompartment?: boolean;
  isTenantSalesAnalytics?: boolean;
  handleChangeCandidate?: (mostHighScoreProduct: ProductCandidateType) => void;
  isBucketType?: boolean;
  displayAnalyticsDataIndex?: number;
};

export const ProductCandidateRealogram: FC<Props> = ({
  products = [],
  selectedItem,
  referenceImage,
  handleModalOpen,
  handleOpenReferenceImagePreview,
  realogramDetailView,
  productSalesReport,
  profitTab,
  setSelectedProductZoomIn,
  realogramCandidateId,
  isCanNotEditProductAndCompartment,
  isTenantSalesAnalytics,
  handleChangeCandidate,
  isBucketType = false,
  displayAnalyticsDataIndex = 0,
  shouldButtonBeDisabled,
  selectNext,
  selectPrevious,
}) => {
  const isInferred = isInferredAsProduct(selectedItem.item);
  const product = useMemo(() => {
    if (!isInferred) return;
    return products?.find(
      (product) =>
        product.id === selectedItem.item?.primary_candidate?.product_id
    );
  }, [isInferred, products, selectedItem.item?.primary_candidate?.product_id]);
  const { name, eanCode, productCode } = getProductDisplayValues(
    selectedItem.item,
    product
  );

  const mostHighScore = useMemo(() => {
    if (!selectedItem.item) return;
    return findMostHighScore(selectedItem.item);
  }, [selectedItem.item]);

  const { data: mostHighScoreProduct } = useGetProductQuery(
    {
      productId: mostHighScore?.product_id ?? 0,
    },
    {
      skip: !mostHighScore?.product_id,
    }
  );
  const eanCodeValue = eanCode ? eanCode : '-';
  const productCodeValue = productCode ? productCode : '-';

  const isProductUnknown =
    selectedItem?.item?.is_unknown && selectedItem?.item?.in_stock;
  const { isLarger } = useBreakpoint();
  const isShowJANAndCD = isInferred && realogramDetailView === 'default';
  // 不明な商品の場合
  const isUnrevisedUnknownProduct =
    selectedItem.item.is_unknown && selectedItem.item.in_stock;

  return (
    <Box component="div" display="flex" flexDirection="column" py={0.5}>
      {/* separated the components for vertical and horizontal screens due to the numerous conditional branches in the styling. */}
      {isLarger ? (
        <HorizontalCandidate
          isBucketType={isBucketType}
          isProductUnknown={isProductUnknown}
          realogramDetailView={realogramDetailView}
          referenceImage={referenceImage}
          product={product}
          selectedItem={selectedItem}
          name={name}
          isShowJANAndCD={isShowJANAndCD}
          eanCodeValue={eanCodeValue}
          productCodeValue={productCodeValue}
          isCanNotEditProductAndCompartment={isCanNotEditProductAndCompartment}
          productSalesReport={productSalesReport}
          profitTab={profitTab}
          realogramCandidateId={realogramCandidateId}
          isTenantSalesAnalytics={isTenantSalesAnalytics}
          mostHighScoreProduct={mostHighScoreProduct?.product}
          mostHighScore={mostHighScore}
          isUnrevisedUnknownProduct={isUnrevisedUnknownProduct}
          slideButtons={
            !isBucketType && (
              <SlideButtons
                selectNext={selectNext}
                selectPrevious={selectPrevious}
                shouldButtonBeDisabled={shouldButtonBeDisabled}
              />
            )
          }
          displayAnalyticsDataIndex={displayAnalyticsDataIndex}
          productCode={productCode}
          handleOpenReferenceImagePreview={handleOpenReferenceImagePreview}
          setSelectedProductZoomIn={setSelectedProductZoomIn}
          handleModalOpen={handleModalOpen}
          handleChangeCandidate={handleChangeCandidate}
        />
      ) : (
        <VerticalCandidate
          isBucketType={isBucketType}
          isProductUnknown={isProductUnknown}
          realogramDetailView={realogramDetailView}
          referenceImage={referenceImage}
          product={product}
          selectedItem={selectedItem}
          name={name}
          isShowJANAndCD={isShowJANAndCD}
          eanCodeValue={eanCodeValue}
          productCodeValue={productCodeValue}
          isCanNotEditProductAndCompartment={isCanNotEditProductAndCompartment}
          productSalesReport={productSalesReport}
          profitTab={profitTab}
          realogramCandidateId={realogramCandidateId}
          isTenantSalesAnalytics={isTenantSalesAnalytics}
          mostHighScoreProduct={mostHighScoreProduct?.product}
          mostHighScore={mostHighScore}
          isUnrevisedUnknownProduct={isUnrevisedUnknownProduct}
          slideButtons={
            !isBucketType && (
              <SlideButtons
                selectNext={selectNext}
                selectPrevious={selectPrevious}
                shouldButtonBeDisabled={shouldButtonBeDisabled}
              />
            )
          }
          displayAnalyticsDataIndex={displayAnalyticsDataIndex}
          handleOpenReferenceImagePreview={handleOpenReferenceImagePreview}
          setSelectedProductZoomIn={setSelectedProductZoomIn}
          handleModalOpen={handleModalOpen}
          handleChangeCandidate={handleChangeCandidate}
        />
      )}
    </Box>
  );
};
