import { getRealogramPermission } from '@hooks/useGetRealogramPermission';
import { Box, ListItemButton, ListItemText, Typography } from '@mui/material';
import { selectionStoreModal } from '@reducers/selectionStoreModal/selector';
import { useAppSelector } from '@store/index';
import { AppAbility } from '@utils/abac';
import { FC, Fragment } from 'react';
import { theme } from 'theme';
import { RealogramDirectory, RealogramDirectorySearch } from 'types/realogram';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockIcon from '@mui/icons-material/Lock';
import PinDropIcon from '@mui/icons-material/PinDrop';
import StoreIcon from '@mui/icons-material/Store';
import { StarIcon } from '@components/molecules/starIcon/starIcon';
import { BreadcrumbForSearch } from '@components/molecules/breadcrumbForSearch/breadcrumbForSearch';
type Props = {
  handleClickListItem: (item: RealogramDirectory) => void;
  directory: RealogramDirectory;
  userId?: number;
  ability: AppAbility;
};

export const DirectoryListItem: FC<Props> = ({
  handleClickListItem,
  directory,
  userId,
  ability,
}) => {
  const { name, type, store_area_id, store_id, store_bay_id, id } = directory;
  const { selectedItemId } = useAppSelector(selectionStoreModal);

  const isDisabled = store_id
    ? (getRealogramPermission(
        {
          action: 'create_file',
          isCan: false,
          isCandidate: false,
          realogram: directory,
        },
        ability
      ).isNotEnable as boolean)
    : false;

  const isSelected = selectedItemId === id;
  const isFavorite = directory.favorite?.owner_id === userId;

  return (
    <ListItemButton
      component="div"
      sx={{
        borderBottom: `1px solid ${theme.palette.dividerBlack.light}`,
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: isSelected ? theme.palette.primary.selected : '',
        '&:hover': {
          backgroundColor: isSelected ? theme.palette.primary.selected : '',
        },
        height: '56px',
        cursor: isDisabled ? 'auto' : 'pointer',
        width: '100%',
      }}
      onClick={() => {
        if (isDisabled) return;
        handleClickListItem(directory);
      }}
    >
      <Box component="div" display="flex" gap={1} alignItems="center">
        {type === 'directory' && store_area_id && (
          <PinDropIcon htmlColor={theme.palette.icons.secondary} />
        )}
        {type === 'directory' && (store_id || store_bay_id) && (
          <StoreIcon htmlColor={theme.palette.icons.secondary} />
        )}
        <ListItemText
          primary={
            <Fragment>
              <Box component="div">
                <Typography variant="body2">
                  {name}
                  {isFavorite && (
                    <StarIcon
                      htmlColor={theme.palette.confidence.middle}
                      sx={{
                        marginLeft: '4px',
                        width: '16px',
                        height: '16px',
                        verticalAlign: 'text-bottom',
                      }}
                    />
                  )}
                </Typography>
                {(directory as RealogramDirectorySearch).breadcrumb && (
                  <BreadcrumbForSearch
                    breadcrumbs={
                      (directory as RealogramDirectorySearch).breadcrumb
                    }
                    screenType="Main"
                    fontSize="12px"
                  />
                )}
              </Box>
            </Fragment>
          }
          primaryTypographyProps={{
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            color: isDisabled
              ? theme.palette.textBlack.disabled
              : theme.palette.textBlack.primary,
          }}
        />
      </Box>
      {type === 'directory' && !store_id && (
        <ChevronRightIcon htmlColor={theme.palette.icons.secondary} />
      )}
      {isSelected && type === 'directory' && store_id && (
        <CheckCircleIcon
          fontSize="small"
          htmlColor={theme.palette.primary.main}
          sx={{ alignItems: 'center' }}
        />
      )}
      {isDisabled && (
        <LockIcon
          htmlColor={theme.palette.icons.secondary}
          sx={{ width: '20px', height: '20px' }}
        />
      )}
    </ListItemButton>
  );
};
