import { FC, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Header, headerHeight } from '@components/organisms/header/header';
import { GuideButtonArea } from '../buttons/guideButtonArea';
import {
  ChangeStep,
  RealogramFormSubmit,
  RealogramFormControl,
  RealogramStoreBayData,
  RealogramType,
} from 'types/realogram';
import { FormAlertDialogs } from './realogramForm/alerts/formAlertDialogs';
import { FlatRealogramForm } from './realogramForm/forms/flatRealogramForm';
import { RealogramForm } from './realogramForm/forms/realogramForm';
import { BayPlanCode } from 'types/bayPlan';
import { RealogramFormData } from '../../types/realogram';
type Props = {
  firstFile?: File;
  secondFile?: File;
  onSecondFileSelect: (file: File) => void;
  failedDialogOpen: boolean;
  failedDialogOpenForbidden: boolean;
  onSingleRealogramSubmit: (data: RealogramFormData) => Promise<void>;
  onFlatRealogramSubmit: (data: RealogramFormData) => Promise<void>;
  onFirstFileSelect: (file: File) => void;
  handleCloseFailedDialog: () => void;
  handleCloseFailedDialogForbidden: () => void;
  changeStep: ChangeStep;
  selectedRealogramType: RealogramType;
  prevNavigate: () => void;
  onSwapFile: (file: File | undefined, secondFile: File | undefined) => void;
  isFlat: boolean;
  isMd: boolean;
  setFailedDialogOpen: (isFailedDialogOpen: boolean) => void;
  control: RealogramFormControl;
  storeBayData?: RealogramStoreBayData;
  bayPlanCodes?: BayPlanCode[];
  disabled: boolean;
  isStoreBaysLoading: boolean;
  handleSubmit: RealogramFormSubmit;
  handleOpenModal: () => void;
  setFormValue: (type: 'storeId' | 'storeBayId', id: number) => void;
};

export const StepFour: FC<Props> = ({
  firstFile,
  secondFile,
  onSecondFileSelect,
  failedDialogOpen,
  onSingleRealogramSubmit,
  onFlatRealogramSubmit,
  onFirstFileSelect,
  handleCloseFailedDialog,
  handleCloseFailedDialogForbidden,
  failedDialogOpenForbidden,
  changeStep,
  selectedRealogramType,
  prevNavigate,
  onSwapFile,
  isFlat,
  isMd,
  control,
  storeBayData,
  bayPlanCodes,
  disabled,
  isStoreBaysLoading,
  handleSubmit,
  handleOpenModal,
  setFormValue,
}) => {
  const [quitDialogOpen, setQuitDialogOpen] = useState(false);

  const onSubmit = isFlat
    ? handleSubmit(onFlatRealogramSubmit)
    : handleSubmit(onSingleRealogramSubmit);

  const uploadBtnDisabled = isFlat
    ? disabled || !firstFile || !secondFile
    : disabled || !firstFile;

  return (
    <>
      <Box
        component="form"
        onSubmit={onSubmit}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        height="100vh"
        pt={`${headerHeight}px`}
      >
        <Header position="fixed">
          <Typography variant="headingS">新規スキャン アップロード</Typography>
        </Header>
        {isFlat ? (
          <FlatRealogramForm
            firstFile={firstFile}
            failedDialogOpen={failedDialogOpen}
            failedDialogOpenForbidden={failedDialogOpenForbidden}
            onSubmit={onFlatRealogramSubmit}
            onFirstFileSelect={onFirstFileSelect}
            handleCloseFailedDialog={handleCloseFailedDialog}
            handleCloseFailedDialogForbidden={handleCloseFailedDialogForbidden}
            selectedRealogramType={selectedRealogramType}
            secondFile={secondFile}
            onSecondFileSelect={onSecondFileSelect}
            onSwapFile={onSwapFile}
            isFlat={isFlat}
            isMd={isMd}
            memoRow={2}
            control={control}
            storeBayData={storeBayData}
            bayPlanCodes={bayPlanCodes}
            handleOpenModal={handleOpenModal}
            setFormValue={setFormValue}
          />
        ) : (
          <RealogramForm
            file={firstFile}
            failedDialogOpen={failedDialogOpen}
            failedDialogOpenForbidden={failedDialogOpenForbidden}
            isFlat={isFlat}
            onSubmit={onSingleRealogramSubmit}
            onFileSelect={onFirstFileSelect}
            handleCloseFailedDialog={handleCloseFailedDialog}
            handleCloseFailedDialogForbidden={handleCloseFailedDialogForbidden}
            selectedRealogramType={selectedRealogramType}
            isMd={isMd}
            memoRow={2}
            control={control}
            storeBayData={storeBayData}
            bayPlanCodes={bayPlanCodes}
            handleOpenModal={handleOpenModal}
            setFormValue={setFormValue}
            isStoreBaysLoading={isStoreBaysLoading}
          />
        )}
        <GuideButtonArea
          changeStep={changeStep}
          selectedRealogramType={selectedRealogramType}
          currentStep="four"
          setQuitDialogOpen={() => setQuitDialogOpen(true)}
          isFlat={isFlat}
          disabled={uploadBtnDisabled}
        />
      </Box>
      <FormAlertDialogs
        quitDialogOpen={quitDialogOpen}
        setQuitDialogOpen={setQuitDialogOpen}
        failedDialogOpen={failedDialogOpen}
        handleCloseFailedDialog={handleCloseFailedDialog}
        uploadButton={<Button onClick={onSubmit}>アップロード</Button>}
        failedDialogOpenForbidden={failedDialogOpenForbidden}
        handleCloseFailedDialogForbidden={handleCloseFailedDialogForbidden}
        prevNavigate={prevNavigate}
      />
    </>
  );
};
