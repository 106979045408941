/**
 * URLのクエリを元に再描画に必要な値を返すhooks
 */
import { useNavigateToDirectory } from '@hooks/useNavigateToDirectory';
import { useUsersApi } from '@hooks/useUsersApi';
import { useGetUserQuery } from '@reducers/shelfAppsApi';
import { useAppSelector } from '@store/index';
import { SidebarValue, defaultShelvesViewMode, paths } from '@utils/const';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ShelvesViewMode } from 'types/common';
import { Condition, RealogramListOrder } from 'types/realogram';
import { User } from 'types/user';
import { useRerenderingSidebar } from './useRenderingSidebar';

type OwnerId = {
  ownerId: number[] | undefined;
  param: 'me' | number[] | undefined;
};

enum QueryKey {
  view = 'view',
  ownerId = 'owner_id',
  firstOrder = 'first_order',
  status = 'status',
  name = 'name',
  isStar = 'star',
  directoryId = 'directoryId',
  directoryType = 'directoryType',
  isSearching = 'isSearching',
  directoryName = 'directoryName',
}

const allowedFirstOrder = [
  'created_at_asc',
  'created_at_desc',
  'updated_at_asc',
  'updated_at_desc',
  'name_asc',
  'name_desc',
  'bay_plan_code_asc',
  'bay_plan_code_desc',
];
const allowedFirstOrderInViewedTab = [
  'current_user_accessed_at_asc',
  'current_user_accessed_at_desc',
];

type Props = {
  directoryId?: string;
};

export const useRerenderingScanner = ({ directoryId }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { user: me } = useAppSelector((state) => state.Auth);

  const { users } = useUsersApi();
  const { data: userData, isLoading: userDataLoading } = useGetUserQuery({
    userId: 'me',
  });
  const { navigateToRootDirectory, navigateToSelectedDirectory } =
    useNavigateToDirectory({ folderPath: paths.actuals.folders });
  const {
    sidebarValue,
    isFilteredByFavorite,
    isFilteredByLatest,
    isFilteredByViewed,
    defaultFirstOrderBySideBar,
    navigateBySidebarValue,
    getFirstOrderQueryParamsForCurrentSideBar,
  } = useRerenderingSidebar<RealogramListOrder>({
    allowedFirstOrder,
    allowedFirstOrderInViewedTab,
    paths: {
      folders: paths.actuals.folders,
      foldersStarred: paths.actuals.foldersStarred,
      foldersRecentlyScanned: paths.actuals.foldersRecentlyScanned,
      foldersRecentlyViewed: paths.actuals.foldersRecentlyViewed,
    },
    defaultFirstOrderBySideBar: {
      all: undefined,
      favorite: 'created_at_desc',
      latest: 'created_at_desc',
      viewed: 'current_user_accessed_at_desc',
    },
  });

  // Mapping condition to sync to search query url
  const makeSearchParams = useCallback(
    (conditionData: Condition, viewMode: ShelvesViewMode, me?: User) => {
      const allowedKeys = [
        QueryKey.view,
        QueryKey.ownerId,
        QueryKey.firstOrder,
        QueryKey.status,
        QueryKey.name,
        QueryKey.isStar,
        QueryKey.directoryType,
        QueryKey.isSearching,
        QueryKey.directoryName,
      ];
      // value order should accord to allowedKeys
      const ownerIdQuery =
        me?.id && conditionData.ownerId?.at(0) === me.id
          ? 'me'
          : conditionData.ownerId?.toString();
      const queryValues = [
        viewMode,
        ownerIdQuery,
        conditionData.firstOrder,
        conditionData.status?.at(0),
        conditionData.name,
        conditionData?.isStar?.toString(),
        conditionData.directoryType,
        conditionData?.isSearching?.toString(),
        conditionData.directoryName,
      ];

      // remove invalid query from query URL
      searchParams.forEach((_, key) => {
        if (!allowedKeys.includes(key as QueryKey)) searchParams.delete(key);
      });

      allowedKeys.forEach((key, index) => {
        const value = queryValues[index];
        if (value) {
          searchParams.set(key, value);
        } else {
          searchParams.delete(key);
        }
      });
      return searchParams;
    },
    [searchParams]
  );

  // owner_idを取得
  const getOwnerIdQueryParams = useCallback(
    (users?: User[], me?: User): OwnerId => {
      const param = searchParams.get(QueryKey.ownerId);

      // meが取得できない場合は、undefined
      if (!me) {
        return {
          ownerId: undefined,
          param: undefined,
        };
      }

      // パラメータが存在しない場合、undefined
      if (!param) {
        return {
          ownerId: undefined,
          param: undefined,
        };
      }

      // パラメータがmeであれば、meデータを返す
      if (param === 'me') {
        return {
          ownerId: [me.id],
          param: 'me',
        };
      } else {
        const ownerId = users
          ?.filter((user) => user.id == parseInt(param, 10))
          .map((user) => user.id);

        if (!ownerId || ownerId.length === 0) {
          return {
            ownerId: undefined,
            param: undefined,
          };
        }

        return {
          ownerId,
          param: ownerId,
        };
      }
    },
    [searchParams]
  );

  const getStatusQueryParams = useCallback(
    (userId?: number) => {
      const param = searchParams.get(QueryKey.status);

      if (param && userId) {
        return {
          status: param,
        };
      } else {
        return {
          status: undefined,
        };
      }
    },
    [searchParams]
  );

  const getNameQueryParams = useCallback((): string | undefined => {
    const param = searchParams.get(QueryKey.name);
    if (!param) return;
    return param;
  }, [searchParams]);

  const getIsStarQueryParams = useCallback((): string | undefined => {
    const param = searchParams.get(QueryKey.isStar);
    if (!param) return;
    return param;
  }, [searchParams]);

  const getDirectoryType = useCallback((): string | undefined => {
    const param = searchParams.get(QueryKey.directoryType);
    if (!param) return;
    return param;
  }, [searchParams]);
  const getIsSearchingQueryParam = useCallback((): string | undefined => {
    const param = searchParams.get(QueryKey.isSearching);
    if (!param) return;
    return param;
  }, [searchParams]);
  const getDirectoryNameQueryParam = useCallback((): string | undefined => {
    const param = searchParams.get(QueryKey.directoryName);
    if (!param) return;
    return param;
  }, [searchParams]);

  const getConditionFromQueryUrl = useCallback(
    (owner: { users?: User[]; me?: User }, userId?: number) => {
      return {
        firstOrder: getFirstOrderQueryParamsForCurrentSideBar(),
        ownerId: getOwnerIdQueryParams(owner.users, owner.me),
        status: getStatusQueryParams(userId)?.status,
        name: getNameQueryParams(),
        isStar: getIsStarQueryParams(),
        directoryType: getDirectoryType(),
        isSearching: getIsSearchingQueryParam(),
        directoryName: getDirectoryNameQueryParam(),
      };
    },
    [
      getFirstOrderQueryParamsForCurrentSideBar,
      getOwnerIdQueryParams,
      getStatusQueryParams,
      getNameQueryParams,
      getIsStarQueryParams,
      getDirectoryType,
      getIsSearchingQueryParam,
      getDirectoryNameQueryParam,
    ]
  );

  /**
   * Mapping condition from query URL
   */
  const condition = useMemo((): Condition => {
    const selector = getConditionFromQueryUrl({ users, me }, userData?.user.id);
    const isSearching = selector.isSearching === 'true' ? true : undefined;
    return {
      ownerId: selector.ownerId?.ownerId,
      status: selector.status ? [selector.status] : undefined,
      firstOrder:
        sidebarValue === 'all' && !isSearching
          ? selector.firstOrder
          : selector.firstOrder ?? 'created_at_desc',
      name: selector.name,
      isStar: selector.isStar === 'true' ? true : undefined,
      directoryAncestorId: directoryId ? directoryId : '0',
      directoryType: selector.directoryType,
      isSearching: selector.isSearching === 'true' ? true : undefined,
      directoryName: selector.directoryName ?? undefined,
    };
  }, [
    getConditionFromQueryUrl,
    me,
    userData?.user.id,
    users,
    directoryId,
    sidebarValue,
  ]);

  const viewMode = useMemo((): ShelvesViewMode => {
    const param = searchParams.get(QueryKey.view) as ShelvesViewMode;
    if (['table', 'grid'].includes(param)) return param;
    return defaultShelvesViewMode;
  }, [searchParams]);

  const setViewMode = useCallback(
    (newViewMode: ShelvesViewMode) => {
      const newSearchParams = makeSearchParams(condition, newViewMode, me);
      setSearchParams(newSearchParams);
    },
    [condition, makeSearchParams, me, setSearchParams]
  );

  /**
   * @description set condition and sync its value to query url
   * @param conditionData new condition value
   * @param sideBarValue sync condition to query url then navigate to the sidebar value
   * @param isToRootDirectory sync condition to query url then navigate to the root directory
   * @param directoryId sync condition to query url then navigate to the selected directory
   * @param replace should create new entry on the history stack.
   */
  const setCondition = useCallback(
    (
      newCondition: Condition,
      options?: {
        sideBarValue?: SidebarValue;
        isToRootDirectory?: boolean;
        directoryId?: string;
        replace?: boolean;
      }
    ) => {
      const newSearchParams = makeSearchParams(newCondition, viewMode, me);
      if (options?.directoryId) {
        // navigate to selected directory
        navigateToSelectedDirectory(options.directoryId, newSearchParams);
        return;
      }
      if (options?.isToRootDirectory) {
        // navigate to root directory
        navigateToRootDirectory(newSearchParams);
        return;
      }
      if (options?.sideBarValue) {
        // navigate to side bar value
        navigateBySidebarValue(options.sideBarValue, newSearchParams);
        return;
      }
      // just sync query url
      setSearchParams(newSearchParams, { replace: !!options?.replace });
    },
    [
      makeSearchParams,
      me,
      navigateBySidebarValue,
      navigateToRootDirectory,
      navigateToSelectedDirectory,
      setSearchParams,
      viewMode,
    ]
  );

  return {
    viewMode,
    condition,
    setViewMode,
    setCondition,
    conditionData: {
      users,
      me,
      userData,
      userDataLoading,
    },
    sidebarData: {
      sidebarValue,
      isFilteredByFavorite,
      isFilteredByLatest,
      isFilteredByViewed,
      defaultFirstOrderBySideBar,
    },
  };
};
