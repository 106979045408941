import { ListSubheader } from '@components/molecules/listSubheader/listSubheader';
import { Box } from '@mui/material';
import { isCompartmentMode, MutableRefs } from '@utils/realogram';
import { FC, Fragment, useState } from 'react';
import { theme } from 'theme';
import { Product, ProfitTab, ShelfDetailView } from 'types/common';
import {
  AnalyticsProductData,
  RealogramCandidateCompartment,
  RealogramCandidateFace,
  RealogramSelectedItem,
  RealogramShelfBoard,
} from 'types/realogram';
import { EmptyCompartment } from './emptyCompartment';
import { ListRow } from './listRow';
type Props = {
  shelfBoardIndex: number;
  refs: MutableRefs;
  products?: Product[];
  shelfBoards?: RealogramShelfBoard[];
  realogramDetailView: ShelfDetailView;
  selectedItem?: RealogramSelectedItem;
  handleClick: (item: RealogramSelectedItem) => void;
  handleClickDeleteShelfBoard: (shelfBoardId: string) => void;
  handleCorrectDrawerOpen: (item: RealogramSelectedItem) => void;
  handleDivisionModalOpen: (face: RealogramCandidateFace) => void;
  handleClickDeleteFace: (item: RealogramSelectedItem) => void;
  handleClickOutSideRealogramBbox: () => void;
  profitTab: ProfitTab;
  productsSalesReport?: AnalyticsProductData[];
  handleSort: () => void;
  isAscending?: boolean;
  isCanEditFace?: boolean;
  isCanDeleteFace?: boolean;
  isCanDeleteBaypart?: boolean;
  isBucketType?: boolean;
  handleAddFaceMode: (shelfBoardIndex: number) => void;
  compartments: RealogramCandidateCompartment[];
  shelfBoardId: string;
  shotIndex: number;
  pathname: string;
};

export const CompartmentsList: FC<Props> = ({
  shelfBoardIndex,
  refs,
  products,
  realogramDetailView,
  selectedItem,
  handleClick,
  handleClickDeleteShelfBoard,
  handleCorrectDrawerOpen,
  handleDivisionModalOpen,
  handleClickDeleteFace,
  handleAddFaceMode,
  handleClickOutSideRealogramBbox,
  profitTab,
  productsSalesReport,
  isCanEditFace,
  isCanDeleteFace,
  isCanDeleteBaypart,
  isBucketType,
  compartments,
  shelfBoardId,
  shotIndex,
  pathname,
}) => {
  const subHeaderTitle = isBucketType ? 'カゴ目' : '段目';
  const [isSKU, setIsSKU] = useState(true);
  const handleChangeSKU = () => {
    setIsSKU(!isSKU);
    handleClickOutSideRealogramBbox();
  };
  const skuViewData = compartments?.reduce((acc, curr) => {
    curr.faces.forEach(({ primary_candidate, ...face }) => {
      if (!primary_candidate || !face.in_stock || face.is_unknown) return acc;
      acc.set(primary_candidate?.product_id, {
        ...face,
        primary_candidate,
        compartmentId: curr.id,
      });
    });
    return acc;
  }, new Map<number, RealogramCandidateFace & { compartmentId: string }>());
  const skuViewCompartments = Array.from(skuViewData.values()).map((el) => {
    return {
      id: el.compartmentId,
      faces: [el],
    };
  });

  const compartmentsToDisplay = isSKU ? skuViewCompartments : compartments;

  return (
    <Box
      component="ul"
      sx={{ padding: 0 }}
      ref={(i) => refs.current.set(shelfBoardId, i as HTMLDivElement)}
    >
      {realogramDetailView === 'default' && (
        <ListSubheader
          title={`${shelfBoardIndex + 1}${subHeaderTitle}`}
          backgroundColor={theme.palette.backgroundBlack.active}
          color={theme.palette.textBlack.primary}
          sx={{
            fontWeight: 400,
            height: 30,
            justifyContent: 'space-between',
          }}
          isSKU={isSKU}
          handleChangeSKU={handleChangeSKU}
          isRealogram
          handleClick={() => handleAddFaceMode(shelfBoardIndex)}
          isCanEditFace={isCanEditFace}
          isBucket={isBucketType}
        />
      )}
      {compartmentsToDisplay.length === 0 &&
      realogramDetailView === 'default' ? (
        <EmptyCompartment
          isCanDeleteBaypart={isCanDeleteBaypart}
          handleClickDeleteShelfBoard={() =>
            handleClickDeleteShelfBoard(shelfBoardId)
          }
          emptyText={
            isBucketType
              ? 'カゴに配置されている商品はありません'
              : '棚に配置されている商品はありません'
          }
          isSKU={isSKU}
        />
      ) : (
        compartmentsToDisplay.map(({ id: compartmentId, faces }) => {
          return faces.map((face, faceIndex) => {
            if (
              isCompartmentMode(realogramDetailView, pathname) &&
              faceIndex > 0
            ) {
              //show each compartment
              return <Fragment key={face.id} />;
            }
            const realogramItem: RealogramSelectedItem = {
              shelfBoardId,
              compartmentId,
              item: face,
            };
            const productReport = productsSalesReport?.find(
              (product) =>
                product.product_id === face.primary_candidate?.product_id
            );
            return (
              <ListRow
                key={face.id}
                realogramItem={realogramItem}
                faces={faces}
                products={products}
                selectedItem={selectedItem}
                handleClick={(item: RealogramSelectedItem) =>
                  handleClick({
                    ...item,
                    shelfBoardId,
                    shotIndex,
                    isBboxClicked: false,
                    isSKU,
                  })
                }
                refs={refs}
                handleClickDeleteFace={handleClickDeleteFace}
                realogramDetailView={realogramDetailView}
                handleCorrectDrawerOpen={handleCorrectDrawerOpen}
                profitTab={profitTab}
                productReport={productReport}
                handleDivisionModalOpen={handleDivisionModalOpen}
                isCanEditFace={isCanEditFace}
                isCanDeleteFace={isCanDeleteFace}
                isSKU={isSKU}
                isBucket={isBucketType}
              />
            );
          });
        })
      )}
    </Box>
  );
};
