import { RealogramCandidate, RealogramDirectory } from 'types/realogram';

export const generateRealogramDirectories = (
  candidates?: RealogramCandidate[]
) => {
  if (!candidates) return {};
  return candidates?.reduce((acc, curr, index) => {
    const storeId = curr.store_bay.store.id;
    const storeName = curr.store_bay.store.name;
    const storeBayId = curr.store_bay.id;
    if (!acc[storeName]) {
      acc[storeName] = {
        type: 'directory',
        store_id: storeId,
        store_bay_id: storeBayId,
        id: index.toString(),
        name: storeName,
        current_user_role: { role: 'editor' }, //TODO: Update after API is ready
        // current_user_role: curr.current_user_role,
      } as RealogramDirectory;
    }
    return acc;
  }, {} as Record<string, RealogramDirectory>);
};
